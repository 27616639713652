import React from 'react';
import Router from 'next/router';

class Index extends React.Component {
  componentDidMount() {
    Router.push('/intro');
  }

  render() {
    return null;
  }
}

export default Index;
